import 'alpine-magic-helpers'; //@Alpine Extensionr: https://www.alpinetoolbox.com/extensions/
import Alpine from 'alpinejs';
import { Transitions } from '../transitions';

import {
  Solar,
} from '../solar';
import {
  Carousel,
  Store,
} from '../util';

const preloader = document.querySelector('.preloader');

const observer = new IntersectionObserver(function(entries) {
  if (entries[0].intersectionRatio === 0 && entries[0].boundingClientRect.y < 1){
    document.querySelector(".boxed-navigation").classList.remove("is-unstuck");
  } else {
    document.querySelector(".boxed-navigation").classList.add("is-unstuck");
  }
}, {
  threshold: [0, 1]
});

export const Common = {
  init() {
    window.Alpine = Alpine;
    Alpine.start();
    Store.setup();
    Solar.start();
    Carousel.init();
    observer.observe(document.querySelector(".boxed-navigation-flag"));
    preloader.classList.remove('is-loading');
  },
  finalize() {
    window.useInertia.before(() => {
    })
    window.useInertia.after(() => {
      Carousel.init();
      observer.observe(document.querySelector(".boxed-navigation-flag"));
    })
    window.inertia.setup.transitions = Transitions;
    window.inertia.init(window.inertia.setup);

    // const slider = document.querySelector('.carrusel');
    // let sliderSection = document.querySelectorAll('.carrusel-box');
    //   if(slider && sliderSection.length > 0){
    //     let sliderSectionLast = sliderSection[sliderSection.length -1];
  
    //     slider.insertAdjacentElement('afterbegin', sliderSectionLast);
    //     function Next(){
    //       if(slider){
    //         let sliderSectionFirst = document.querySelectorAll('.carrusel-box')[0];
    //         slider.style.transform = 'translateX(0)';
    //         slider.style.transition = 'all 3s';
    //         setTimeout(() => {
    //           if(sliderSectionFirst){
    //             slider.style.transition = 'none';
    //             slider.style.transform = 'translateX(20%)';
    //             slider.insertAdjacentElement('beforeend', sliderSectionFirst);
    //           }
    //         }, 3000);
    //       }
    //     }
    //     setInterval(function(){
    //       Next();
    //     }, 3080);
    //   }

  },
};
